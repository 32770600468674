import { ref, onMounted, onUnmounted } from 'vue';
import breakpoints from '@/data/breakpoints.json';

export default function useResizableMediaQuery() {

  const screenSize = ref("default");
  const innerWidth = ref(0); // Initialize with 0 or any default value

  const updateScreenSize = () => {
    if (typeof window !== "undefined") {
      const width = window.innerWidth;
      innerWidth.value = width;

      if (width >= breakpoints["2xl"]) {
        screenSize.value = "2xl";
      } else if (width >= breakpoints.xl) {
        screenSize.value = "xl";
      } else if (width >= breakpoints.lg) {
        screenSize.value = "lg";
      } else if (width >= breakpoints.md) {
        screenSize.value = "md";
      } else if (width >= breakpoints.sm) {
        screenSize.value = "sm";
      } else {
        screenSize.value = "default";
      }
    }
  };

  onMounted(() => {
    updateScreenSize();
    window.addEventListener("resize", updateScreenSize);
  });

  onUnmounted(() => {
    window.removeEventListener("resize", updateScreenSize);
  });

  return {
    screenSize,
    innerWidth,
  };
}
